<template>
    <div
        class="absolute -right-[1px] top-full z-50 mt-[30px] w-[450px] rounded-lg border border-slate-200 bg-white shadow-[5px_5px_8px_#00000029]"
    >
        <!-- Header -->
        <div class="border-b border-slate-100 px-[16px] pb-[17px] pt-[13px]">
            <h3 class="text-xl font-black">
                Winkelwagen ({{ cartStore.totalItems }})
            </h3>
        </div>

        <!-- Empty State or Cart Items -->
        <div class="max-h-[400px] overflow-y-auto">
            <template v-if="cartStore.items.length === 0">
                <div class="flex flex-col items-center justify-center px-4 py-12">
                    <svg
                        class="mb-4 h-16 w-16 text-gray-300"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                            d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                        />
                    </svg>
                    <h4 class="mb-2 font-bold text-gray-700">
                        Uw winkelwagen is leeg
                    </h4>
                    <p class="text-center text-sm text-gray-demegro-light">
                        Er zijn nog geen artikelen toegevoegd aan uw winkelwagen
                    </p>
                </div>
            </template>

            <template v-else>
                <NuxtLink
                    v-for="(item, index) in cartStore.items"
                    :key="item.id"
                    :to="`${item.article.slug}`"
                    @click="emit('close')"
                >
                    <CartButtonPreviewItem :item="item" />
                    <div v-if="index < cartStore.items.length - 1" class="h-px w-full bg-slate-100">
                        <!-- this is a divider between all the lines -->
                    </div>
                </NuxtLink>
            </template>
        </div>

        <!-- Footer with total -->
        <div class="flex items-center justify-between border-t border-slate-100 p-[16px]">
            <span class="text-lg font-bold">Totaal</span>
            <span class="text-lg font-bold">
                {{ cartStore.items.length === 0 ? '€ 0,00' : `€ ${cartStore.totalPrice.toFixed(2)}` }}
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['close'])

const cartStore = useCartStore()
</script>
