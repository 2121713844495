<template>
    <form @submit.prevent="handleSubmit">
        <div class="flex flex-col px-8 py-8 gap-6">
            <div class="inline-flex flex-col gap-2">
                <label for="username" class="">E-mailadres</label>
                <input
                    id="username"
                    v-model="email"
                    type="email"
                    name="username"
                    autocomplete="username email"
                    class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition duration-200"
                    required
                >
            </div>
            <div class="inline-flex flex-col gap-2">
                <label for="password" class="">Wachtwoord</label>
                <input
                    id="password"
                    v-model="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition duration-200"
                    required
                    @keyup.enter="handleSubmit()"
                >
                </input>
            </div>

            <div class="flex items-center justify-center gap-4">
                <button
                    class="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
                    @click="emit('modalClose')"
                >
                    Annuleren
                </button>
                <div class="relative flex items-center">
                    <button
                        class="px-4 py-2 bg-blue-demegro text-white rounded-lg hover:bg-dark-blue-demegro transition-colors duration-200 shadow-md hover:shadow-lg"
                        @click="handleSubmit()"
                    >
                        Inloggen
                    </button>
                    <div v-if="loading" class="absolute -right-8">
                        <font-awesome-icon :icon="['fas', 'spinner-third']" class="fa-spin text-2xl text-blue-demegro" />
                    </div>
                </div>
            </div>

            <TransitionGroup name="fade">
                <div v-if="errorMessage" key="error" class="mt-4 p-3 rounded-md" :class="[errorMessage.colors?.bg, errorMessage.colors?.border, errorMessage.colors?.border ? 'border' : '']">
                    <p class="text-sm" :class="errorMessage.colors?.text">
                        {{ errorMessage.message }}
                    </p>
                </div>
            </TransitionGroup>
        </div>
    </form>
</template>

<script setup lang="ts">
const emit = defineEmits(['loginSuccess', 'modalClose'])

const email = ref('')
const password = ref('')
const error = ref<null | { status: number, message: string }>(null)
const loading = ref(false)

const { getErrorFallback } = useErrorMessages()

const errorMessage = computed(() => {
    if (!error.value)
        return null

    switch (error.value.status) {
        case 400:
            return getErrorFallback('login_failed')
        case 401:
            return getErrorFallback('invalid_credentials')
        default:
            return getErrorFallback('error')
    }
})

async function handleSubmit() {
    loading.value = true
    error.value = null

    try {
        await useAuthStore().handleLogin({ email: email.value, password: password.value })
        emit('loginSuccess')
    } catch (err: any) {
        error.value = err
    } finally {
        loading.value = false
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
