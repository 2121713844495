<template>
    <div class="flex items-center">
        <div v-if="image" class="mr-4 h-12 w-12 flex-shrink-0">
            <DPicture :src="image?.src" class="" />
        </div>
        <div class="min-w-0 flex-grow">
            <h4 class="truncate text-sm font-medium text-gray-900">
                {{ article.title }}
            </h4>
            <p v-if="article.manufacturer_name" class="text-sm text-gray-500">
                {{ article.manufacturer_name }}
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Article } from '~/types/demegro'

const props = defineProps<{
    article: Article
}>()

const image = computed(() => useArticle().getImage(props.article))
</script>
